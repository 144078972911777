<template>
  <div>
    <div class="fixed bottom-4 right-4 z-10">
      <button
        class="h-10 w-10 bg-white border border-gray-200 text-gray-500 rounded-full flex items-center justify-center"
        onclick="window.scrollTo({ top: 0, behavior: 'smooth' })"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class=""
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"
          />
        </svg>
      </button>
    </div>
  </div>
</template>
