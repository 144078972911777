export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"theme-color","content":"#1F8CE6"},{"key":"og:type","property":"og:type","content":"website"},{"key":"og:site_name","property":"og:site_name","content":"Remak"},{"key":"og:title","property":"og:title","content":"Remak"},{"key":"og:description","property":"og:description","content":"Your Personal AI Knowledge Base."},{"key":"og:image","property":"og:image","content":"https://remak.io/og-image.png"},{"key":"og:image:secure_url","property":"og:image:secure_url","content":"https://remak.io/og-image.png"},{"key":"og:image:type","property":"og:image:type","content":"image/png"},{"key":"og:image:width","property":"og:image:width","content":"1800"},{"key":"og:image:height","property":"og:image:height","content":"1800"},{"key":"twitter:description","property":"twitter:description","content":"Your Personal AI Knowledge Base."},{"key":"twitter:image","property":"twitter:image","content":"https://remak.io/og-image.png"},{"key":"twitter:image:alt","property":"twitter:image:alt","content":"Remak"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon.ico"}],"style":[],"script":[],"noscript":[],"title":"Remak"}

export const appBaseUrl = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnUrl = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const appManifest = true

export const remoteComponentIslands = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'