import { default as indexLVF5Wb6pW4Meta } from "/home/runner/work/remak-frontend/remak-frontend/pages/chat/index.vue?macro=true";
import { default as _91collectionName_93ldey7FXh4UMeta } from "/home/runner/work/remak-frontend/remak-frontend/pages/collection/[collectionName].vue?macro=true";
import { default as index8g0vHBqX9kMeta } from "/home/runner/work/remak-frontend/remak-frontend/pages/collection/index.vue?macro=true";
import { default as indexGhlFXqJThaMeta } from "/home/runner/work/remak-frontend/remak-frontend/pages/index.vue?macro=true";
import { default as loginMLycNxCyPhMeta } from "/home/runner/work/remak-frontend/remak-frontend/pages/login.vue?macro=true";
import { default as mainZHCXQOCkYEMeta } from "/home/runner/work/remak-frontend/remak-frontend/pages/main.vue?macro=true";
import { default as privacy_45policyHMO9aD7VghMeta } from "/home/runner/work/remak-frontend/remak-frontend/pages/privacy-policy.vue?macro=true";
import { default as change_45passwordWrQTsy8ehGMeta } from "/home/runner/work/remak-frontend/remak-frontend/pages/profile/change-password.vue?macro=true";
import { default as editpHfXBAIzXJMeta } from "/home/runner/work/remak-frontend/remak-frontend/pages/profile/edit.vue?macro=true";
import { default as index3Bxym3S1hZMeta } from "/home/runner/work/remak-frontend/remak-frontend/pages/profile/index.vue?macro=true";
import { default as withdrawkG8BJ5PvUlMeta } from "/home/runner/work/remak-frontend/remak-frontend/pages/profile/withdraw.vue?macro=true";
import { default as redirectjDVhWkOO6HMeta } from "/home/runner/work/remak-frontend/remak-frontend/pages/redirect.vue?macro=true";
import { default as registeruZCc5ZesZMMeta } from "/home/runner/work/remak-frontend/remak-frontend/pages/register.vue?macro=true";
import { default as resetPasswordrM3Hn0dhu5Meta } from "/home/runner/work/remak-frontend/remak-frontend/pages/resetPassword.vue?macro=true";
import { default as indexw5ChimjXV6Meta } from "/home/runner/work/remak-frontend/remak-frontend/pages/search/index.vue?macro=true";
import { default as _91tagName_93db7iHgorXuMeta } from "/home/runner/work/remak-frontend/remak-frontend/pages/tag/[tagName].vue?macro=true";
import { default as indexbidwdV5Q6cMeta } from "/home/runner/work/remak-frontend/remak-frontend/pages/tag/index.vue?macro=true";
import { default as terms_45of_45serviceM6xjDgvA9QMeta } from "/home/runner/work/remak-frontend/remak-frontend/pages/terms-of-service.vue?macro=true";
import { default as _91docId_93nnlBTD0RwVMeta } from "/home/runner/work/remak-frontend/remak-frontend/pages/view/[docId].vue?macro=true";
export default [
  {
    name: indexLVF5Wb6pW4Meta?.name ?? "chat",
    path: indexLVF5Wb6pW4Meta?.path ?? "/chat",
    meta: indexLVF5Wb6pW4Meta || {},
    alias: indexLVF5Wb6pW4Meta?.alias || [],
    redirect: indexLVF5Wb6pW4Meta?.redirect || undefined,
    component: () => import("/home/runner/work/remak-frontend/remak-frontend/pages/chat/index.vue").then(m => m.default || m)
  },
  {
    name: _91collectionName_93ldey7FXh4UMeta?.name ?? "collection-collectionName",
    path: _91collectionName_93ldey7FXh4UMeta?.path ?? "/collection/:collectionName()",
    meta: _91collectionName_93ldey7FXh4UMeta || {},
    alias: _91collectionName_93ldey7FXh4UMeta?.alias || [],
    redirect: _91collectionName_93ldey7FXh4UMeta?.redirect || undefined,
    component: () => import("/home/runner/work/remak-frontend/remak-frontend/pages/collection/[collectionName].vue").then(m => m.default || m)
  },
  {
    name: index8g0vHBqX9kMeta?.name ?? "collection",
    path: index8g0vHBqX9kMeta?.path ?? "/collection",
    meta: index8g0vHBqX9kMeta || {},
    alias: index8g0vHBqX9kMeta?.alias || [],
    redirect: index8g0vHBqX9kMeta?.redirect || undefined,
    component: () => import("/home/runner/work/remak-frontend/remak-frontend/pages/collection/index.vue").then(m => m.default || m)
  },
  {
    name: indexGhlFXqJThaMeta?.name ?? "index",
    path: indexGhlFXqJThaMeta?.path ?? "/",
    meta: indexGhlFXqJThaMeta || {},
    alias: indexGhlFXqJThaMeta?.alias || [],
    redirect: indexGhlFXqJThaMeta?.redirect || undefined,
    component: () => import("/home/runner/work/remak-frontend/remak-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginMLycNxCyPhMeta?.name ?? "login",
    path: loginMLycNxCyPhMeta?.path ?? "/login",
    meta: loginMLycNxCyPhMeta || {},
    alias: loginMLycNxCyPhMeta?.alias || [],
    redirect: loginMLycNxCyPhMeta?.redirect || undefined,
    component: () => import("/home/runner/work/remak-frontend/remak-frontend/pages/login.vue").then(m => m.default || m)
  },
  {
    name: mainZHCXQOCkYEMeta?.name ?? "main",
    path: mainZHCXQOCkYEMeta?.path ?? "/main",
    meta: mainZHCXQOCkYEMeta || {},
    alias: mainZHCXQOCkYEMeta?.alias || [],
    redirect: mainZHCXQOCkYEMeta?.redirect || undefined,
    component: () => import("/home/runner/work/remak-frontend/remak-frontend/pages/main.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyHMO9aD7VghMeta?.name ?? "privacy-policy",
    path: privacy_45policyHMO9aD7VghMeta?.path ?? "/privacy-policy",
    meta: privacy_45policyHMO9aD7VghMeta || {},
    alias: privacy_45policyHMO9aD7VghMeta?.alias || [],
    redirect: privacy_45policyHMO9aD7VghMeta?.redirect || undefined,
    component: () => import("/home/runner/work/remak-frontend/remak-frontend/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: change_45passwordWrQTsy8ehGMeta?.name ?? "profile-change-password",
    path: change_45passwordWrQTsy8ehGMeta?.path ?? "/profile/change-password",
    meta: change_45passwordWrQTsy8ehGMeta || {},
    alias: change_45passwordWrQTsy8ehGMeta?.alias || [],
    redirect: change_45passwordWrQTsy8ehGMeta?.redirect || undefined,
    component: () => import("/home/runner/work/remak-frontend/remak-frontend/pages/profile/change-password.vue").then(m => m.default || m)
  },
  {
    name: editpHfXBAIzXJMeta?.name ?? "profile-edit",
    path: editpHfXBAIzXJMeta?.path ?? "/profile/edit",
    meta: editpHfXBAIzXJMeta || {},
    alias: editpHfXBAIzXJMeta?.alias || [],
    redirect: editpHfXBAIzXJMeta?.redirect || undefined,
    component: () => import("/home/runner/work/remak-frontend/remak-frontend/pages/profile/edit.vue").then(m => m.default || m)
  },
  {
    name: index3Bxym3S1hZMeta?.name ?? "profile",
    path: index3Bxym3S1hZMeta?.path ?? "/profile",
    meta: index3Bxym3S1hZMeta || {},
    alias: index3Bxym3S1hZMeta?.alias || [],
    redirect: index3Bxym3S1hZMeta?.redirect || undefined,
    component: () => import("/home/runner/work/remak-frontend/remak-frontend/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: withdrawkG8BJ5PvUlMeta?.name ?? "profile-withdraw",
    path: withdrawkG8BJ5PvUlMeta?.path ?? "/profile/withdraw",
    meta: withdrawkG8BJ5PvUlMeta || {},
    alias: withdrawkG8BJ5PvUlMeta?.alias || [],
    redirect: withdrawkG8BJ5PvUlMeta?.redirect || undefined,
    component: () => import("/home/runner/work/remak-frontend/remak-frontend/pages/profile/withdraw.vue").then(m => m.default || m)
  },
  {
    name: redirectjDVhWkOO6HMeta?.name ?? "redirect",
    path: redirectjDVhWkOO6HMeta?.path ?? "/redirect",
    meta: redirectjDVhWkOO6HMeta || {},
    alias: redirectjDVhWkOO6HMeta?.alias || [],
    redirect: redirectjDVhWkOO6HMeta?.redirect || undefined,
    component: () => import("/home/runner/work/remak-frontend/remak-frontend/pages/redirect.vue").then(m => m.default || m)
  },
  {
    name: registeruZCc5ZesZMMeta?.name ?? "register",
    path: registeruZCc5ZesZMMeta?.path ?? "/register",
    meta: registeruZCc5ZesZMMeta || {},
    alias: registeruZCc5ZesZMMeta?.alias || [],
    redirect: registeruZCc5ZesZMMeta?.redirect || undefined,
    component: () => import("/home/runner/work/remak-frontend/remak-frontend/pages/register.vue").then(m => m.default || m)
  },
  {
    name: resetPasswordrM3Hn0dhu5Meta?.name ?? "resetPassword",
    path: resetPasswordrM3Hn0dhu5Meta?.path ?? "/resetPassword",
    meta: resetPasswordrM3Hn0dhu5Meta || {},
    alias: resetPasswordrM3Hn0dhu5Meta?.alias || [],
    redirect: resetPasswordrM3Hn0dhu5Meta?.redirect || undefined,
    component: () => import("/home/runner/work/remak-frontend/remak-frontend/pages/resetPassword.vue").then(m => m.default || m)
  },
  {
    name: indexw5ChimjXV6Meta?.name ?? "search",
    path: indexw5ChimjXV6Meta?.path ?? "/search",
    meta: indexw5ChimjXV6Meta || {},
    alias: indexw5ChimjXV6Meta?.alias || [],
    redirect: indexw5ChimjXV6Meta?.redirect || undefined,
    component: () => import("/home/runner/work/remak-frontend/remak-frontend/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: _91tagName_93db7iHgorXuMeta?.name ?? "tag-tagName",
    path: _91tagName_93db7iHgorXuMeta?.path ?? "/tag/:tagName()",
    meta: _91tagName_93db7iHgorXuMeta || {},
    alias: _91tagName_93db7iHgorXuMeta?.alias || [],
    redirect: _91tagName_93db7iHgorXuMeta?.redirect || undefined,
    component: () => import("/home/runner/work/remak-frontend/remak-frontend/pages/tag/[tagName].vue").then(m => m.default || m)
  },
  {
    name: indexbidwdV5Q6cMeta?.name ?? "tag",
    path: indexbidwdV5Q6cMeta?.path ?? "/tag",
    meta: indexbidwdV5Q6cMeta || {},
    alias: indexbidwdV5Q6cMeta?.alias || [],
    redirect: indexbidwdV5Q6cMeta?.redirect || undefined,
    component: () => import("/home/runner/work/remak-frontend/remak-frontend/pages/tag/index.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45serviceM6xjDgvA9QMeta?.name ?? "terms-of-service",
    path: terms_45of_45serviceM6xjDgvA9QMeta?.path ?? "/terms-of-service",
    meta: terms_45of_45serviceM6xjDgvA9QMeta || {},
    alias: terms_45of_45serviceM6xjDgvA9QMeta?.alias || [],
    redirect: terms_45of_45serviceM6xjDgvA9QMeta?.redirect || undefined,
    component: () => import("/home/runner/work/remak-frontend/remak-frontend/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: _91docId_93nnlBTD0RwVMeta?.name ?? "view-docId",
    path: _91docId_93nnlBTD0RwVMeta?.path ?? "/view/:docId()",
    meta: _91docId_93nnlBTD0RwVMeta || {},
    alias: _91docId_93nnlBTD0RwVMeta?.alias || [],
    redirect: _91docId_93nnlBTD0RwVMeta?.redirect || undefined,
    component: () => import("/home/runner/work/remak-frontend/remak-frontend/pages/view/[docId].vue").then(m => m.default || m)
  }
]